.text-updater-node {
    height: 100px;
    width: 300px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    background: white;
    position: relative;
}

.text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
}

.text-updater-node input {
    display: block;
    width: 100%;
}

.node-buttons{
    position: absolute;
    top: 0px;
    right: 5px;
}

.node-buttons button {
    font-size: 8px;
    margin-left: 2px;
}

.choice-handle label{
    position: absolute;
    bottom: -20px;
}